<template>
  <div class="">
    <v-file-input
      :label="label"
      :error-messages="errorMessage"
      v-bind="$attrs"
      @change="onInputFile"
    >
      <template v-slot:selection="{ text }">
        <v-chip small label color="primary">
          {{ text }}
        </v-chip>
      </template>
      <template v-slot:counter="{ props }"
        ><span>{{ props.value }}</span></template
      >
    </v-file-input>

    <div class="m-0" v-if="hasAttachments">
      <p><strong>Archivos cargados</strong></p>
      <v-chip
        class="mx-1"
        color="success"
        v-for="(a, index) in attachments"
        :key="index"
        >{{ a.name }}</v-chip
      >
    </div>
  </div>
</template>

<script>
import { watch, ref, computed, onMounted } from "@vue/composition-api";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";

export default {
  props: {
    label: {
      default: "Adjuntar archivos (.pdf) máximo 3MB",
      type: String,
    },
    attachments: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { emit, attrs }) {
    const { attachments } = props;
    const errorMessage = ref(null);

    const hasAttachments = computed(() => {
      return attachments.length;
    });

    const fileRules = (file) =>
      !file ||
      file.size > 3000000 ||
      !file.type ||
      !attrs?.accept?.includes(file.type);

    const onInputFile = (files) => {
      errorMessage.value = "";
      let validation = null;

      if (Array.isArray(files)) validation = !!files.find((f) => fileRules(f));
      else validation = fileRules(files);

      validation
        ? (errorMessage.value = `Debes seleccionar un archivo ${attrs?.accept} (max 3MB)`)
        : emit("onInputFiles", { files });
    };
    return {
      errorMessage,
      hasAttachments,
      onInputFile,
    };
  },
};
</script>

<style></style>
