<template>
  <wrapper-dialog v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <a
        v-bind="attrs"
        v-on.stop="on"
      >
        Añadir categorias
      </a>
    </template>

    <template>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Añadir categorias al proyecto
        </v-card-title>

        <v-card-text>
          <WrapperForm :model="addFormData" v-model="isValidForm">
            <SelectSkills
              multiple
              label="Categorias"
              placeholder="Ej: Pintura, Construcción, Plomeria"
              v-model="addFormData.tags"
              :rules="[validators.required]"
              class="my-8"
            />
          </WrapperForm>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="onAddProjectSkills"
            :disabled="!isValidForm"
          >
            Añadir
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </wrapper-dialog>
</template>

<script>
import WrapperDialog from '@/components/general/WrapperDialog'
import SelectSkills from "@/components/general/SelectSkills";
import WrapperForm from '@/components/forms/WrapperForm';

import { required } from "@core/utils/validation";
import api from "@/services";

import {
  ref,
  reactive
} from "@vue/composition-api";
export default {
  name: "AddProjectSkills",
  props: {
    projectId: {
      type: Number,
      default: null,
      required: true
    },
  },
  components: { WrapperDialog, SelectSkills, WrapperForm },

  setup(props, { emit }) {
    const { projectId } = props;
    const dialog = ref(false)
    const isValidForm = ref(false)

    const addFormData = reactive({
      tags: null,
    });

    const onAddProjectSkills = async () => {
      try {
        const {data: response} = await api.addProjectSkills(projectId, addFormData);
        const { data } = response;
        
        emit("on-update-skills", data);
      } catch (error) { 
      } finally {
        dialog.value = false;
        addFormData.tags = null;
      }
    }

    return {
      dialog,
      isValidForm,
      addFormData,

      validators: {
        required,
      },

      onAddProjectSkills
    }
  }
}
</script>

<style>

</style>