<template>
  <div class="text-center">
    <v-dialog
      :value="value"
      width="500"
      @input="$emit('input', $event)"
      >
      <template v-slot:activator="slotData">
        <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotData" />
        <v-btn v-else v-on="slotData.on">Abrir</v-btn>
     </template>

      <slot></slot>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "WrapperDialog",
  props: {
    value: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style>

</style>