<template>
  <div>
    <v-card class="mt-0 mb-8">
      <v-card-title>
        <span class="text-sm-h5 text-subtitle-1 font-weight-semibold"
          >Proyectos publicados</span
        >
      </v-card-title>
      <v-card-subtitle
        >Gestiona tus Proyectos y recibe los mejores Presupuestos de nuestra
        comunidad de Profesionales</v-card-subtitle
      >

      <v-btn
        color="primary"
        class="my-4 mx-4"
        rounded
        @click="onDrawerToggle(true)"
      >
        Nuevo Proyecto
      </v-btn>
    </v-card>

    <section
      class="wrapper-all-projects bg--white rounded-lg shadow-lg overflow-hidden"
    >
      <!-- <v-row>
        <v-col cols="12" xs="12" sm="10"
          ><v-row>
            <v-col cols="12" xs="12" sm="3">
              <p class="text-subtitile-2 font-weight-semibold">
                Filtrar por ciudad
              </p>
              <SelectLocationCities v-model="cityId" label="Buscar" />
            </v-col> </v-row
        ></v-col>
        <v-col cols="12" xs="12" sm="2" class="d-flex justify-end align-center">
          <v-btn @click="onClearAllFilters" plain small>Limpiar filtros</v-btn>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="5">
          <Scroll-Div
            width="100%"
            :height="$vuetify.breakpoint.mobile ? '340px' : '550px'"
            view-class="px-2"
          >
            <div class="d-flex flex-column gap-10">
              <div class="">
                <!-- <p class="text-subtitle-2 font-weight-light mb-4">
                  Resultados de la búsqueda
                </p> -->
                <pagination
                  v-if="projects.length"
                  :pagination="pagination"
                  @input="onInputPaginate"
                  light
                  :total-visible="5"
                  class="w-100"
                />
              </div>

              <v-card
                v-for="(project, index) in projects"
                @click="onViewProject(project)"
                :key="index"
              >
                <v-card-title>
                  <span
                    class="text-subtitle-1 font-weight-semibold primary--text"
                  >
                    {{ project.name }}
                  </span></v-card-title
                >
                <v-card-subtitle>
                  <div class="">
                    <v-icon small>{{ icons.mdiOfficeBuilding }}</v-icon>
                    {{ project.company.company }}
                  </div>

                  <div class="">
                    <v-icon small>{{ icons.mdiMapMarker }}</v-icon>
                    {{ project.city_name }} {{ project.location_name }}
                  </div>
                </v-card-subtitle>

                <v-card-text>
                  <div class="d-flex justify-space-between">
                    <v-chip small>{{ project.created_at }}</v-chip>
                    <v-chip small color="primary">{{
                      project.total_quotations
                    }}</v-chip>
                  </div>
                </v-card-text>
              </v-card>

              <pagination
                v-if="projects.length"
                :pagination="pagination"
                @input="onInputPaginate"
                light
                :total-visible="5"
                class="w-100"
              />
            </div>
          </Scroll-Div>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="7">
          <Scroll-Div
            width="100%"
            height="550px"
            view-class="px-2 d-none d-sm-block"
          >
            <info-project :init-project="projectActive" />
          </Scroll-Div>
        </v-col>
      </v-row>
    </section>

    <WrapperDrawer
      title="NUEVO PROYECTO"
      v-model="drawerToggle"
      @close="onDrawerToggle"
    >
      <v-form ref="addProjectForm" @submit.prevent="handleFormSubmit">
        <p>
          <span class="text-subtitle-1 text--primary font-weight-semibold"
            >Información del proyecto
          </span>
        </p>

        <FormTextField
          label="Titulo del proyecto"
          v-model="addFormData.name"
          :rules="[validators.required]"
          class="my-8"
        />

        <TextFieldPicker
          label="Vigencia de cotización"
          v-model="addFormData.date"
          :rules="[validators.required]"
          class="my-8"
        />

        <SelectSkills
          multiple
          label="Categorias"
          placeholder="Ej: Pintura, Construcción, Plomeria"
          v-model="addFormData.tags"
          :rules="[validators.required]"
          class="my-8"
        />

        <FormTextArea
          label="Descripción"
          v-model="addFormData.description"
          :rules="[validators.required]"
          class="my-8"
        />

        <SelectLocationCities
          v-model="addFormData.city_id"
          :rules="[validators.selected]"
          class="my-8"
        />

        <p class="mb-6">
          <span class="text-subtitle-1 text--primary font-weight-semibold"
            >Otras configuraciones (opcional)
          </span>
        </p>

        <FormFileInput
          label="Adjuntar imagen referencia"
          accept="image/png, image/jpeg, image/bmp"
          :prepend-icon="icons.mdiCamera"
          class="my-6"
          @onInputFiles="({ files }) => onInputFiles(files)"
        />

        <SelectRange
          v-model="addFormData.max_receive_quotation"
          label="Máximo número de presupuestos a recibir"
          :rules="[validators.selected]"
          class="my-6"
        />

        <FormTextField
          label="Rango de valor de presupuestos a recibir"
          :placeholder="'100 - 1000..., Abierto'"
          v-model="addFormData.range_receive_quotation"
          class="my-6"
        />

        <SelectLocationComunes
          v-model="addFormData.location_id"
          :rules="[]"
          class="my-6"
        />

        <v-row>
          <v-col>
            <v-btn
              rounded
              color="primary"
              :loading="loading"
              :disabled="loading"
              type="submit"
              class="float-right mt-10"
            >
              Agregar Proyecto
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </WrapperDrawer>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  getCurrentInstance,
  onMounted,
} from "@vue/composition-api";
import FormTextField from "@/components/forms/TextField";
import FormTextArea from "@/components/forms/TextArea";
import FormFileInput from "@/components/forms/FileInput";
import WrapperDrawer from "@/components/general/WrapperDrawer";
import SelectSkills from "@/components/general/SelectSkills";
import TextFieldPicker from "@/components/forms/TextFieldPicker";
import SelectLocationCities from "@/components/general/SelectLocationCities";
import SelectRange from "@/components/general/SelectRange";
import SelectLocationComunes from "@/components/general/SelectLocationComunes";
import Pagination from "@/components/general/Pagination";
import InfoProject from "@/components/company/project/InfoProject";

import { required, selected } from "@core/utils/validation";
import api from "@/services";
import { useRouter, getVuetify } from "@core/utils";
import { getFormData } from "@/assets/utils";
import { mdiOfficeBuilding, mdiMapMarker, mdiCamera } from "@mdi/js";

export default {
  name: "CompanyProjectPublish",
  components: {
    WrapperDrawer,
    FormTextField,
    FormTextArea,
    FormFileInput,
    SelectSkills,
    TextFieldPicker,
    SelectLocationCities,
    SelectRange,
    SelectLocationComunes,
    Pagination,
    InfoProject,
  },
  setup() {
    const $vuetify = getVuetify();
    const vm = getCurrentInstance().proxy;
    const { router } = useRouter();

    const projects = ref([]);
    const projectActive = ref(null);

    const pagination = ref(null);

    const drawerToggle = ref(false);
    const loading = ref(false);

    const addFormData = reactive({
      name: "",
      date: "",
      description: "",
      tags: "",
      file: "",
      city_id: "",
      location_id: "",
      max_receive_quotation: 7,
      range_receive_quotation: "Abierto",
    });

    const onViewProject = (project) => {
      if ($vuetify.breakpoint.mobile) {
        router.push({
          name: "company.projects.show",
          params: { id: project.id },
        });
      } else {
        projectActive.value = project;
      }
    };

    const onInputPaginate = (page) => {
      getProjects(page);
    };

    const onDrawerToggle = (value) => {
      drawerToggle.value = value;
    };

    const onChangeFormInputs = (value, property) => {
      addFormData[property] = value;
    };

    const onToggleLoading = () => {
      loading.value = !loading.value;
    };

    const onInputFiles = (value) => {
      addFormData.file = value;
    };

    const handleFormSubmit = async () => {
      const addProjectForm = vm.$refs.addProjectForm;

      if (!addProjectForm.validate()) return;

      onToggleLoading();

      try {
        const formData = getFormData(addFormData);

        const { data: response } = await api.addProjects(formData);
        const { data } = response;

        if (data) {
          projects.value.unshift(data);
        }

        vm.$alert("Proyecto registrado exitosamente", null, "success");
        addProjectForm.reset();
      } catch (error) {
        vm.$alert("Ocurrio un error al registrar su proyecto", null, "error");
      }

      onToggleLoading();
    };

    const getProjects = async (page = 1) => {
      try {
        const { data: response } = await api.getProjects({
          params: {
            page,
          },
        });
        const { data, meta } = response;

        projects.value = data;
        pagination.value = { ...meta };

        if (data.length) projectActive.value = data[0];
      } catch (error) {}
    };

    onMounted(() => {
      getProjects();
    });

    return {
      validators: {
        required,
        selected,
      },

      icons: {
        mdiOfficeBuilding,
        mdiMapMarker,
        mdiCamera,
      },

      loading,
      projects,
      projectActive,
      pagination,
      addFormData,
      drawerToggle,

      onViewProject,
      onDrawerToggle,
      handleFormSubmit,
      onChangeFormInputs,
      onInputFiles,
      onToggleLoading,
      onInputPaginate,
    };
  },
};
</script>

<style></style>
