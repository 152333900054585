<template>
  <div class="">
    <span v-if="text">
      {{ textSkills }}
    </span>
    <v-chip
      v-else
      v-for="(skill, index) in skillsArr"
      class="v-chip-light-bg secondary--text mr-2 mb-2"
      color="secondary"
      :key="index"
      :close="isEnableDelete"
      @click:close="onDeleteSkill(index, skill)"
    >
      {{ skill.name }}
    </v-chip>
  </div>
</template>

<script>
import { computed, ref, watch } from "@vue/composition-api";
import api from "@/services";

export default {
  props: {
    text: {
      default: false,
      type: Boolean,
    },
    skills: {
      default: () => [],
      type: Array,
    },
    projectId: {
      type: Number,
      default: null,
    },
    isEnableDelete: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { skills, projectId } = props;
    const textSkills = computed(() => skills.map((s) => s.name).join(", "));

    const skillsArr = ref(skills);

    const onDeleteSkill = async (index, { id: skillId }) => {
      try {
        await api.deleteProjectSkillById({ projectId, skillId });
        skillsArr.value.splice(index, 1);
      } catch (error) {}
    };

    watch(
      () => props.skills,
      (skills) => {
        skillsArr.value = [...skills];
      }
    );

    return {
      skillsArr,
      textSkills,

      onDeleteSkill,
    };
  },
};
</script>

<style></style>
