<template>
  <v-card v-if="project">
    <v-img
      height="250"
      :src="project.image_thumb"
      v-if="project.image_thumb"
    ></v-img>

    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4 d-flex justify-space-between flex-wrap">
          <v-chip>{{ project.created_at }}</v-chip>
          <ProjectStatus :status="project.status" />
        </div>
        <v-list-item-title
          class="text-subtitle-1 text-sm-h5 mb-1 font-weight-semibold primary--text"
        >
          {{ project.name }}
        </v-list-item-title>
        <v-list-item-subtitle
          ><v-icon small>{{ icons.mdiMapMarker }}</v-icon>
          {{ project.city_name }}
          {{ project.location_name }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>

    <v-card-text>
      <div class="d-flex flex-column align-start gap-10">
        <TagSkills
          :skills="project.skills"
          :project-id="projectId"
          is-enable-delete
        />
        <AddProjectSkills
          :project-id="projectId"
          @on-update-skills="onUpdateSkills"
        />
      </div>

      <div class="mt-5 d-flex flex-column gap-16">
        <div class="d-flex justify-space-between flex-wrap">
          <p>
            <span class="text-subtitle-1 text--primary font-weight-semibold"
              >Rango de presupuestos:
            </span>
            <span>{{ project.range_receive_quotation }}</span>
          </p>

          <div class="d-flex justify-end align-center">
            <p class="mb-0">
              <span class="text-subtitle-1 text--primary font-weight-semibold"
                >Vigencia de cotización:
              </span>
              <span>{{ project.date_dmy }}</span>
            </p>

            <EditProjectDate
              :project-id="projectId"
              @on-update-project="onUpdateProject"
              class="mx-3"
            />
          </div>
        </div>

        <div class="d-flex flex-column">
          <p>
            <span class="text-subtitle-1 text--primary font-weight-semibold"
              >Descripción
            </span>
          </p>

          {{ project.description }}
        </div>

        <div class="d-flex flex-column">
          <p>
            <span class="text-subtitle-1 text--primary font-weight-semibold"
              >Adjuntos
            </span>
          </p>
          <TagFiles :attachments="project.attachments" />
        </div>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-tabs
        class="my-4 wrapper-tabs"
        hide-slider
        style="box-shadow: none !important"
      >
        <v-tab :href="`#quotes`"
          >Cotizaciones recibidas
          <v-chip color="primary" class="mx-2" small>{{
            project.total_quotations
          }}</v-chip></v-tab
        >

        <v-tab-item value="quotes" style="box-shadow: none !important">
          <v-card
            class="mx-4 my-4"
            v-for="(quote, index) in projectQuotations"
            :key="index"
            style="box-shadow: none !important"
          >
            <v-card-title>{{ quote.worker.name }}</v-card-title>

            <v-card-text>
              <v-row align="center" class="mx-0">
                <ReadRating :stars="quote.worker.total_rating" />

                <div class="grey--text ms-4">
                  {{ quote.worker.total_rating }}
                </div>
              </v-row>

              <div class="my-4 text-subtitle-1">
                <span class=""
                  ><v-icon small>{{ icons.mdiOfficeBuilding }}</v-icon>
                  {{ quote.worker.company }}</span
                >
                <span class="mx-4"
                  ><v-icon small>{{ icons.mdiPhone }}</v-icon>
                  {{ quote.worker.phone }}</span
                >
              </div>
            </v-card-text>

            <v-card-actions class="d-flex justify-space-between align-center">
              <v-btn color="primary" rounded @click="onViewQuote(quote)">
                Ver Cotización
              </v-btn>
              <div class="d-flex gap-10">
                <!-- <BadgeStarts :stars="quote.worker.total_rating" width="20px" /> -->
                <IconFavorite :value="quote.favorite" />
                <IconWin :value="quote.win" />
              </div>
            </v-card-actions>

            <v-divider class="my-4"></v-divider>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  ref,
  computed,
  onMounted,
  reactive,
  getCurrentInstance,
  watch,
} from "@vue/composition-api";

import ProjectStatus from "@/components/general/projects/Status";
import TagSkills from "@/components/general/TagSkills";
import TagFiles from "@/components/general/TagFiles";
import BadgeStarts from "@/components/general/BadgeStarts";
import ReadRating from "@/components/general/ReadRating";
import IconWin from "@/components/icons/Win";
import IconFavorite from "@/components/icons/Favorite";
import EditProjectDate from "@/components/project/EditProjectDate";
import AddProjectSkills from "@/components/project/AddProjectSkills";

import { mdiOfficeBuilding, mdiMapMarker, mdiPhone } from "@mdi/js";
import { useRouter } from "@core/utils";

export default {
  props: {
    initProject: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    ProjectStatus,
    TagSkills,
    TagFiles,
    BadgeStarts,
    ReadRating,
    IconWin,
    IconFavorite,
    EditProjectDate,
    AddProjectSkills,
  },

  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { router } = useRouter();

    const project = ref(props.initProject);
    const projectId = computed(() => project.value.id);

    const projectQuotations = computed(() => {
      return project.value.quotations || [];
    });

    const onUpdateProject = ({ date_dmy }) => {
      project.value.date_dmy = date_dmy;
    };

    const onUpdateSkills = (skills) => {
      project.value.skills = [...skills];
    };

    const onViewQuote = ({ id: quotationId }) => {
      const routeData = router.resolve({
        name: "company.projects.quotations.show",
        params: { quotationId, projectId: projectId.value },
      });
      window.open(routeData.href, "_blank");
    };

    watch(
      () => props.initProject,
      (value) => {
        project.value = value;
      }
    );

    return {
      project,
      projectId,
      projectQuotations,

      icons: {
        mdiOfficeBuilding,
        mdiMapMarker,
        mdiPhone,
      },
      onUpdateProject,
      onUpdateSkills,
      onViewQuote,
    };
  },
};
</script>

<style></style>
