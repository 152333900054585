<template>
  <v-navigation-drawer
    class="wrapper__navigation-drawer py-6"
    @input="onInputDrawer"
    width="600px"
    app
    hide-overlay
    right
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="wrapper__drawer-main py-6 px-6">
      <v-row>
        <v-col>
          <v-btn
            class="mt-6 mt-sm-6 mt-md-0"
            fab
            x-small
            right
            color="primary"
            style="float: right"
            @click="close"
          >
            <v-icon dark> {{ icons.mdiClose }} </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <p class="drawer-title text-uppercase text-center mb-10" v-if="title">
        {{ title }}
      </p>

      <!-- default slot -->
      <slot></slot>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { onMounted, ref, watch } from "@vue/composition-api";
import { mdiClose } from "@mdi/js";

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const onInputDrawer = (value) => {
      emit("input", value);
    };

    const close = () => {
      emit("close", false);
    };

    return {
      onInputDrawer,
      close,

      icons: {
        mdiClose,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper__navigation-drawer {
  .wrapper__drawer-main {
    .drawer-title {
      font-size: 13px !important;
      font-weight: 700;
    }
  }
}
</style>
