<template>
  <v-icon :color="colorIcon">
    {{ icons.mdiHeart }}
  </v-icon>
</template>

<script>
import { computed } from "@vue/composition-api";
import { mdiHeart } from "@mdi/js";

export default {
  props: {
    value: {
      default: Boolean,
    },
  },
  setup(props) {
    const { value } = props;
    const isVale = computed(() => !!value);
    const colorIcon = computed(() => (isVale.value ? "error" : ""));
    return {
      colorIcon,

      icons: {
        mdiHeart,
      },
    };
  },
};
</script>

<style></style>
