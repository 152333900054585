<template>
  <FormSelectField
    :value="value"
    :label="label"
    :items="items"
    v-bind="$attrs"
    v-on="$listeners"
    @input="onInputSelect"
  />
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";

import FormSelectField from "@/components/forms/Select";
import api from "@/services";

export default {
  props: {
    value: {
      default: null
    },
    label: {
      default: ''
    },
    length: {
      default: 7
    },
  },
  components: {
    FormSelectField,
  },

  setup({ length }, { emit }) {
    const items = ref(Array.from({length}, (_, i) => i + 1));

    const onInputSelect = (data) => {
      emit('input', data)
    }

    return {
      items,

      onInputSelect
    };
  },
};
</script>

<style></style>
