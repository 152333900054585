<template>
  <v-dialog ref="dialog" :return-value.sync="dialog" width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="computedDateFormatted"
        readonly
        v-bind="$attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :active-picker.sync="activePicker"
      min="1950-01-01"
      locale="es-ES"
      hint="MM/DD/YYYY format"
      @change="onSaveDate"
    >
    </v-date-picker>
  </v-dialog>
</template>

<script>
import {
  ref,
  watch,
  getCurrentInstance,
  computed,
  onMounted,
} from "@vue/composition-api";

export default {
  name: "TextFieldPicker",
  props: {},
  components: {
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy;

    const activePicker = ref(null);
    const date = ref(null);
    const dialog = ref(false);

    const computedDateFormatted = computed(() => formatDate(date.value));

    const onSaveDate = (date) => {
      emit("input", date);
    };

    const formatDate = (date) => {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    };


    onMounted(() => {
      const { value } = vm.$attrs;
      date.value = value;
    });

    return {
      date,
      computedDateFormatted,
      activePicker,
      dialog,

      onSaveDate,
    };
  },
};
</script>
