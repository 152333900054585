<template>
  <v-textarea
    :value="value"
    :rows="rows"
    auto-grow
    row-height="10"
    hide-details="auto"
    v-bind="$attrs"
    @input="onChangeInput"
></v-textarea>
</template>

<script>
export default {
  props: {
    value: {
      default: null
    },
    rows: {
      default: 15,
      type: Number
    }
  },
  setup(props, { emit }) {
    const onChangeInput = (value) => {
      emit('input', value)
    };

    return {
      onChangeInput
    }
  }  
}
</script>

<style>

</style>