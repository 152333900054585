<template>
  <v-icon :color="colorIcon">
    {{ icons.mdiThumbUp }}
  </v-icon>
</template>

<script>
import { computed } from "@vue/composition-api";
import { mdiThumbUp } from "@mdi/js";

export default {
  props: {
    value: {
      default: Boolean,
    },
  },
  setup(props) {
    const { value } = props;
    const isVale = computed(() => !!value);
    const colorIcon = computed(() => (isVale.value ? "primary" : ""));
    return {
      colorIcon,

      icons: {
        mdiThumbUp,
      },
    };
  },
};
</script>

<style></style>
